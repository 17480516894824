<template>
	<div>
		<header class="header">
			<div class="header_wrapper">
				<top-menu-new
					:fetched-data="fetchedData"
					menu-type="separate"
				/>
			</div>
		</header>
		<div v-for="(block, index) in fetchedData.blocks" :key="index">
			<intersect @enter.once="onIntersect(index)">
				<component
					:is="block.type"
					:ref="block.type"
					:index="index"
					:block="block"
					:content="block.content"
					:fetched-data="fetchedData"
					:static_content="block.static_content"
					:block_title="block.block_title"
				/>
			</intersect>
		</div>
		<client-only v-if="showFooter">
			<main-footer-eng v-if="fetchedData.id === 1542" :fetched-data="fetchedData" />
			<main-footer v-else :fetched-data="fetchedData" />
		</client-only>
	</div>
</template>

<script>
import Intersect from 'vue-intersect';
import panels from '~/mixins/panels';
import createJivoMixin from '~/mixins/createJivoMixin';
import TopMenuNew from '~/components/layouts/_partials/TopMenuNew.vue';
import MainPageHeader from '~/components/layouts/headers/MainPageHeader.vue';
import { mapActions } from 'vuex';
import makeCanonicalLink from '~/utils/seo/makeCanonicalLink';

export default {
	components: {
		Intersect,
		TopMenuNew,
		MainPageHeader,
		MainFooter: () => import(/* webpackChunkName: "footer" */'~/components/layouts/footers/MainFooter.vue'),
		MainFooterEng: () => import(/* webpackChunkName: "footer" */'~/components/layouts/footers/MainFooterEng.vue'),
	},
	mixins: [panels, createJivoMixin()],
	async asyncData({ app, route }) {
		const { $pagesApi } = app;
		const lang = route.fullPath.split('/').includes('en') ? 'en' : 'ru';
		const fetchedData = await $pagesApi.mainPage(lang);
		return { fetchedData };
	},
	data() {
		return {
			fetchedData: [],
			showFooter: false,
		};
	},
	head() {
		return {
			title: this.fetchedData.meta_title,
			link: [
				{
					rel: 'canonical',
					hid: 'canonical',
					href: makeCanonicalLink(this.$route.path),
				},
			],
			meta: [
				{
					hid: 'robots',
					name: 'robots',
					content: this.fetchedData.meta_indexing,
				},
				{
					name: 'description',
					hid: 'description',
					content: this.fetchedData.meta_description,
				},
				// Open Graph
				{
					name: 'og:title',
					hid: 'og:title',
					content: this.fetchedData.meta_title,
				},
				{
					name: 'og:description',
					hid: 'og:description',
					content: this.fetchedData.meta_description,
				},
				{
					name: 'og:type',
					hid: 'og:type',
					content: 'website',
				},
				{
					name: 'og:image',
					hid: 'og:image',
					content: this.fetchedData.smm_image_default.src
						|| process.env.generalOGImage,
				},
				// Twitter Card
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:site',
					content: '@nuxt_js',
				},
				{
					name: 'twitter:title',
					content: this.fetchedData.meta_title,
				},
				{
					name: 'twitter:description',
					content: this.fetchedData.meta_description,
				},
				{
					name: 'twitter:image',
					content: this.fetchedData.smm_image_default.src
						|| process.env.generalOGImage,
				},

				{
					name: 'image',
					property: 'og:image',
					content: this.fetchedData.smm_image_default.src
						|| process.env.generalOGImage,
				},
				{
					name: 'author',
					content: 'SKOLKOVO',
				},
			],
		};
	},
	mounted() {
		const { resetPassword } = this.$route.query;

		if (!this.$auth.user && resetPassword) {
			this.showResetPassword();
		}
	},
	methods: {
		...mapActions({
			showResetPassword: 'authModal/showResetPassword',
		}),
		onIntersect(index) {
			if (index === (this.fetchedData.blocks.length - 1)) {
				this.showFooter = true;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
	@import "~/assets/styles/components/home/event-header";
</style>
