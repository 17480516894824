import { mapGetters, mapActions } from 'vuex';

export default (key, defer = false) => {
	/* @vue/component */
	const mixin = {
		computed: {
			...mapGetters('scripts', ['isJivoLoaded']),
		},
		methods: {
			...mapActions({
				loadJivo: 'scripts/loadJivo',
			}),
			async consult() {
				if (!this.isJivoLoaded) {
					await this.loadJivo();
				} else {
					window.jivo_api.open();
				}
			},
			checkLanguage(checkValue) {
				return this.$route.path.split('/').includes(checkValue);
			},
		},
	};

	if (!defer) {
		mixin.mounted = function mounted() {
			this.$nextTick(() => {
				if (this.checkLanguage('en')) {
					this.loadJivo('AOz2WErP6e');
				} else {
					this.loadJivo();
				}
			});
		};
	}

	if (key) {
		mixin.watch = {
			[key]: {
				handler(chat, old) {
					if (!this.$ssrContext && chat !== old) {
						this.loadJivo();
					}
				},
			},
		};
	}

	return mixin;
};
