<template>
	<header
		class="header"
		:style="headerStyle"
	>
		<video id="main_feature_video" autoplay muted loop class="main_feature_video d-none d-lg-block">
			<source src="/frontimg/campus.mp4" type="video/mp4">
			Your browser does not support HTML5 video.
		</video>

		<div class="header_wrapper">
			<top-menu-new :fetched-data="fetchedData" />
			<div class="container">
				<div class="header_main">
					<div class="row">
						<div v-if="$i18n.locale ==='en'" class="col-12 col-md-11 col-lg-6">
							<div class="title_block_eng">
								<h1>
									{{ $t('mainTitle') }}
								</h1>
							</div>
						</div>
						<div v-else class="col-12 col-md-11 col-lg-6">
							<div class="title_block">
								<h1>
									{{ $t('h1') }}
								</h1>
							</div>
						</div>
						<div v-if="$i18n.locale ==='en'" class="col-12 col-md-9 col-lg-5 offset-lg-1">
							<div class="overview_block">
								<div class="title_eng">
									{{ $t('h1') }}
								</div>
								<div class="btn-panel">
									<button class="btn btn-primary btn-icon icon-video" @click="playVideoEng()">
										{{ $t('show-video') }}
									</button>
								</div>
							</div>
						</div>
						<div v-else class="col-12 col-md-9 col-lg-5 offset-lg-1">
							<div class="overview_block">
								<div class="title">
									{{ $t('prepare') }}
								</div>
								<div class="btn-panel">
									<button class="btn btn-primary btn-icon icon-video" @click="playVideo()">
										{{ $t('show-video') }}
									</button>
								</div>
								<hr>
								<div class="title">
									{{ $t('choose-title') }}
								</div>
								<div class="btn-panel">
									<a
										:href="'/navigator/programmes/?typologies=1'"
										class="btn btn-primary"
									>{{ $t('choose-button') }}</a>
									<a
										id="main_jivosite_top"
										onclick="return jivo_api.open(),!1"
										href="javascript:void(0)"
										class="btn btn-secondary"
										role="button"
									>{{ $t('more-info') }}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<CoolLightBox
			:items="videosSource"
			:full-screen="true"
			:index="indexVideosSource"
			@close="indexVideosSource = null"
		/>
		<CoolLightBox
			:items="videosSourceEng"
			:full-screen="true"
			:index="indexVideosSourceEng"
			@close="indexVideosSourceEng = null"
		/>
	</header>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import TopMenuNew from '~/components/layouts/_partials/TopMenuNew.vue';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

const VIDEO_SRC = 'https://www.youtube.com/watch?v=XloBn79_DyE&feature=youtu.be';
const VIDEO_SRC_ENG = 'https://www.youtube.com/watch?v=zvni38doRZs&feature=youtu.be';

export default {
	name: 'MainPageHeader',
	components: {
		TopMenuNew,
		CoolLightBox,
	},
	props: {
		fetchedData: { type: Object, required: true },
	},
	data() {
		return {
			headerStyle: null,
			videosSource: [],
			videosSourceEng: [],
			indexVideosSource: null,
			indexVideosSourceEng: null,
			// options: {
			// 	slidesPerView: "auto",
			// 	spaceBetween: 0,
			// 	cssMode: true,
			// 	loop: false
			// }
		};
	},
	mounted() {
		if (document.readyState === 'complete') {
			this.init();
		} else {
			window.addEventListener('load', this.init, {
				once: true,
				passive: true,
			});
		}
	},
	methods: {
		init() {
			this.videosSource = [{
				src: VIDEO_SRC,
			}];
			this.videosSourceEng = [{
				src: VIDEO_SRC_ENG,
			}];
			this.loadStyles();
		},
		loadStyles() {
			this.headerStyle = `background-image:url(${this.fetchedData.top_cover_image_default.src})`;
		},
		playVideo(index = 0) {
			this.indexVideosSource = index;
		},
		playVideoEng(index = 0) {
			this.indexVideosSourceEng = index;
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/main-page-header";
	.title_block_eng {
		h1 {
			font-size: 3rem;
			font-weight: 700;
			line-height: 1;
			@include media-breakpoint-up(lg) {
				font-size: 4.5rem;
			}
			@include media-breakpoint-up(xl) {
				font-size: 4rem;
			}
		}
	}
	.title_eng {
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1;

		@include media-breakpoint-up(lg) {
			font-size: 2.1rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 2rem;
		}
	}
</style>
<i18n>
{
	"ru": {
		"h1": "№1 в Восточной Европе по качеству корпоративных программ в рейтинге Financial Times 2020",
		"mainTitle": "Московская школа управления СКОЛКОВО",
		"choose-title": "Подберите программу под ваши задачи",
		"show-video": "Посмотреть видео о школе",
		"choose-button": "Подобрать программу",
		"more-info": "Запросить консультацию",
		"prepare": "Готовим людей, способных развиваться и развивать страну и мир"
	},
	"en": {
		"h1": "No.1 in Eastern Europe in terms of quality of corporate programmes according to the Financial Times 2020 ranking",
		"mainTitle": "SKOLKOVO Business School",
		"choose-title": "Choose a programme that meet your needs:",
		"show-video": "Show video",
		"choose-button": "Choose a programme",
		"more-info": "Get more info",
		"prepare": "We prepare people capable of developing themselves, the country and the world"
	}
}
</i18n>
